import $ from 'jquery';
import 'what-input';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;
//require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment
// the line below
import './lib/foundation-explicit-pieces';

import libs from './lib/dependencies';
window.libs = libs;

$(document).foundation();

const observer = libs.lozad(); // lazy loads elements with default selector as '.lozad'
observer.observe();


$(window).on('load', function() {
    // page is fully loaded, including all files, objects and images
    'use strict';

    vh100();

    // Activate modal if present
    // if(libs.Cookie.get('reveal-messsage')!="hide")
    // {
    //     if($('#reveal-messsage').length > 0){
    //          $('#reveal-messsage').foundation('open');
    //      }

    //      $('#reveal-messsage[data-reveal]').on('closed.zf.reveal', function () {
    //         // var modal = $(this);
    //         libs.Cookie.set('reveal-messsage', 'hide', { expires: 1 });
    //       });
    // }
   
});


/*------------------------------------------------------------
                    100 vh function
https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
------------------------------------------------------------*/

function vh100(){

    var innerHeight = window.innerHeight;

    // if(Foundation.MediaQuery.current == "small"){
    //     var verticalMenuHeight = $("#page-header").outerHeight();
    //     innerHeight = innerHeight - verticalMenuHeight;
    // }

    // Get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}


// Reference: http://www.html5rocks.com/en/tutorials/speed/animations/

let lastKnownScrollPosition = 0;
let ticking = false;

function doSomething(scrollPos) {
    if(scrollPos > 100){
        //$('#main-nav').fadeOut();
        document.documentElement.classList.add('scrolled');
        // document.documentElement.classList.add('light-bg');
    }else{
        //$('#main-nav').fadeIn();
        document.documentElement.classList.remove('scrolled');
        // document.documentElement.classList.remove('light-bg');
        //document.getElementById('main-nav').classList.remove('show');
    }
    //console.log(scrollPos);
  // Do something with the scroll position
}



// https://developer.mozilla.org/en-US/docs/Web/API/Document/scroll_event
window.addEventListener('scroll', () => {
    document.documentElement.style.setProperty('--scroll-y', `${window.scrollY}px`);

    lastKnownScrollPosition = window.scrollY;

    if (!ticking) {
      window.requestAnimationFrame(function() {
        doSomething(lastKnownScrollPosition);
        ticking = false;
      });
  
      ticking = true;
    }
});



/*------------------------------------------------------------
                    H A M B U R G E R S
------------------------------------------------------------*/
// // Look for .hamburger
// var hamburger = document.querySelector(".hamburger");
// // On click
// hamburger.addEventListener("click", function() {
//     // Toggle class "is-active"
//     hamburger.classList.toggle("is-active");
//     // Do something else, like open/close menu
// });

// Look for .hamburger
var menuToogle = document.querySelector(".menu-toggle");
var hamburger = document.querySelector(".hamburger");
// On click
menuToogle.addEventListener("click", function() {
    // Toggle class "is-active"
    hamburger.classList.toggle("is-active");

    // Do something else, like open/close menu
    document.documentElement.classList.toggle('menu-open');
   
});



const openMenu = () => {
document.getElementById('curtain').classList.add('show')
    const scrollY = document.documentElement.style.getPropertyValue('--scroll-y');
    const body = document.body;
    body.style.position = 'fixed';
    body.style.top = `-${scrollY}`;
};
const closeMenu = () => {
    const body = document.body;
    const scrollY = body.style.top;
    body.style.position = '';
    body.style.top = '';
    window.scrollTo(0, parseInt(scrollY || '0') * -1);
    document.getElementById('curtain').classList.remove('show');
}


// $('.media-element').on('click', function(elm){
//     console.log(elm);
//     console.log($('picture', this).data('iesrc'));
// })

// $('.media-element').each(function(){

// });

// $( "div" ).each(function( i ) {
//     if ( this.style.color !== "blue" ) {
//         this.style.color = "blue";
//     } else {
//         this.style.color = "";
//     }
// });



// // (function($) {

//     // Init empty gallery array
//     var container = [];
  
//     // Loop over gallery items and push it to the array
//     $('#gallery').find('figure').each(function() {
//         var $link   = $(this).find('a');
//         var nextElm = $link.find('picture');

//         //if($link.next().is("picture"))
//         //if($link.next().get(0).tagName.toUpperCase() === 'PICTURE')
//         if(nextElm.length)
//         {
//             //console.log('Pushing:' + $link.attr('href'));
//             var item = {
//                 src: $link.attr('href'),
//                 w: $link.data('width'),
//                 h: $link.data('height'),
//                 title: $link.data('caption')
//             };
//             container.push(item);
//         }
//     });
  
//     // Define click event on gallery item
//     $('.case-media a').on('click', function(event) {

//         // Prevent location change
//         event.preventDefault();

//         var isPicture = $(this).find('picture').length;
//         var isVideo = $(this).find('video').length;
        
//         // only show if it is an image
//         if(isPicture){
        
//             // Define object and gallery options
//             var $pswp = $('.pswp')[0],
//                 options = {
//                 index: $(this).parent('figure').index(),
//                 bgOpacity: 0.85,
//                 showHideOpacity: true
//                 };
        
//             // Initialize PhotoSwipe
//             var gallery = new libs.PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
//             gallery.init();

//         }else if(isVideo){
//             var url = $(this).attr('href');
//            if(url.length){
//             CreateRevealAndVideo($(this).attr('href'));
//            }
//         }
//     });
  
// //   }(jQuery));

/*----------------------------------------------
            P R O C E S S  L I N K S
------------------------------------------------*/

// $('a').on( 'click', function(e) {
//     // console.log("Run!" + $(this).attr('href'));
//     if(this.href != "") 
//     { 

//         e.preventDefault();

//         if(this.href.includes('vimeo')){

//             // Check if statistics Cookies is accepted from Cookiebot
//             if (Cookiebot.consent.statistics)
//             {
//                 CreateRevealAndVideo(this.href)

//             }else{
//                 window.location = this.href;
//             }

//         }
//         else if(this.href.includes('youtube')){

//             // Check if marketing Cookies is accepted from Cookiebot
//             if (Cookiebot.consent.marketing)
//             {
//                 CreateRevealAndVideo(this.href)

//             }else{
//                 window.location = this.href;
//             }

//         }
//         else if(this.href.includes('.mp4')){
//             CreateRevealAndVideo(this.href)
//         }
//         //else if(/^#[^ ]+$/.test(this.href)){
//         else if(this.href.includes("#")){
//         // It's an anchor - scroll to position
        
//             var parts = this.href.split('#');
//             if (parts.length > 1) {
//                 //console.log(parts.pop());
//                 Foundation.SmoothScroll.scrollToLoc('#'+parts.pop(), {
//                     animationEasing: 'swing',
//                     animationDuration: 1200,
//                     threshold: 50,
//                     offset: getHeaderHeight(false, 20)
//                 });                
//             }
//         }
//         else if(this.href.includes("/downloads")){
//             $('#download-pop-up').foundation('open');
//         }
//         else{
//             //check if element has _blank
//             if (!this.hasAttribute("target"))
//             {
//                 window.location = this.href;
//             }else{
//                 var win = window.open(this.href, '_blank');
//                 win.focus();
//             }
//         }
//     }
    
// });





// $('a[href*="calendly"]').each(function(e) {
//     console.log(e);
//     if(e===0){
//         // Add Calendly css and script
//         addStylesheet('https://assets.calendly.com/assets/external/widget.css');
//         addScript('https://assets.calendly.com/assets/external/widget.js');
//     }

//     console.log(this.href); 
//     $(this).attr('onClick', 'Calendly.initPopupWidget({url: \'' + this.href + '\'});return false;');
//     $(this).removeAttr('href');
// });

function addScript(url) {
    var s = document.createElement('script');
    s.type = 'text/javascript';
    s.async = true;
    s.src = url;
    var x = document.getElementsByTagName('body')[0];
    x.appendChild(s);
}

function addStylesheet(url) {
    var s = document.createElement('link');
    s.type = 'text/css';
    s.async = true;
    s.rel = 'stylesheet';
    s.href = url;
    var x = document.getElementsByTagName('body')[0];
    x.appendChild(s);
}

// (function($) {

    // Init empty gallery array
    var container = [];
  
    // Loop over gallery items and push it to the array
    $('#gallery').find('figure').each(function() {
        var $link   = $(this).find('a');
        var nextElm = $link.find('picture');

        //if($link.next().is("picture"))
        //if($link.next().get(0).tagName.toUpperCase() === 'PICTURE')
        if(nextElm.length)
        {
            //console.log('Pushing:' + $link.attr('href'));
            var item = {
                src: $link.attr('href'),
                w: $link.data('width'),
                h: $link.data('height'),
                title: $link.data('caption')
            };
            container.push(item);
        }
    });
  
    // Define click event on gallery item
    $('.case-media a').on('click', function(event) {

        // Prevent location change
        event.preventDefault();

        var isPicture = $(this).find('picture').length;
        var isVideo = $(this).find('video').length;
        
        // only show if it is an image
        if(isPicture){
        
            // Define object and gallery options
            var $pswp = $('.pswp')[0],
                options = {
                index: $(this).parent('figure').index(),
                bgOpacity: 0.85,
                showHideOpacity: true
                };
        
            // Initialize PhotoSwipe
            var gallery = new libs.PhotoSwipe($pswp, PhotoSwipeUI_Default, container, options);
            gallery.init();

        }else if(isVideo){
            var url = $(this).attr('href');
           if(url.length){
            CreateRevealAndVideo($(this).attr('href'));
           }
        }
    });
  
//   }(jQuery));



function CreateRevealAndVideo(url){
    
  createReveal('large');
  $('#dynamic-reveal').foundation('open');
  addVideoToReveal(url);
}

function addVideoToReveal(url){
  
  $('<div/>', {
      "class": 'responsive-embed widescreen',
      html:  createVideo(url, "100%"),
  }).appendTo('#dynamic-reveal');
}


function createVideo (url, width, height) {
  // Returns an iframe of the video with the specified URL.
  var videoObj = parseVideo(url);

  if(videoObj.type == 'youtube' || videoObj.type == 'vimeo'){
      var $element = $('<iframe>', { width: width, height: height });
      $element.attr('frameborder', 0);
  }
 
  if (videoObj.type == 'youtube') {
      $element.attr('src', '//www.youtube.com/embed/' + videoObj.id + "?autoplay=1&playsinline=1&rel=0");
  } else if (videoObj.type == 'vimeo') {
      $element.attr('src', '//player.vimeo.com/video/' + videoObj.id + "?autoplay=1&playsinline=1&title=0&byline=0&badge=0&rel=0");
      $element.attr('allow','autoplay; fullscreen');
      $element.attr('allowfullscreen', 'true');
  } else if (videoObj.type == 'local') {
      var $element = $('<video/>', { width: width, height: height });
      $element.attr('src', videoObj.id);
      $element.attr({
          controls: '',
          autoplay: '',
          fullscreen: '',
          playsinline: ''
      });
  }

  return $element;
}


function parseVideo (url) {
  // Edited version of https://gist.github.com/yangshun/9892961 - have included .mp4
  // - Supported YouTube URL formats:
  //   - http://www.youtube.com/watch?v=My2FRPA3Gf8
  //   - http://youtu.be/My2FRPA3Gf8
  //   - https://youtube.googleapis.com/v/My2FRPA3Gf8
  // - Supported Vimeo URL formats:
  //   - http://vimeo.com/25451551
  //   - http://player.vimeo.com/video/25451551
  // - Also supports relative URLs:
  //   - //player.vimeo.com/video/25451551

  url.match(/(http:|https:|)\/\/(player.|www.)?(vimeo\.com|youtu(be\.com|\.be|be\.googleapis\.com))\/(video\/|embed\/|watch\?v=|v\/)?([A-Za-z0-9._%-]*)(\&\S+)?/);

  if (RegExp.$3.indexOf('youtu') > -1) {
      var type = 'youtube';
      var id = RegExp.$6;
  } else if (RegExp.$3.indexOf('vimeo') > -1) {
      var type = 'vimeo';
      var id = RegExp.$6;
  } else {
      var type = 'local';
      var id = url;
  }

  return {
      type: type,
      id: id
  };
}

function createReveal(size){
  var dynamicReveal = document.getElementById("dynamic-reveal");

  //If it isn't "undefined" and it isn't "null", then it exists.
  if(typeof(dynamicReveal) == 'undefined' || dynamicReveal == null){
      
      //var closeButton = '<button class="close-button" data-close aria-label="Luk" type="button"><span aria-hidden="true">&times;</span></button>';
      
      $('<div/>', {
          id: 'dynamic-reveal',
          "class": 'reveal ' + size,
          html: '',//closeButton,
          'data-reveal': 'true',
          'data-animation-in': 'fade-in fast',
          'data-v-offset': 'inherit',//getHeaderHeight(false, 20), // '0' // 0 hvis modal skal være helt i top
          'style': 'padding:0;'
      }).appendTo('body');

      $('#dynamic-reveal').foundation();

      $('#dynamic-reveal[data-reveal]').on('closeme.zf.reveal', function () {
          //the reveal is about to open - close curtain (background)
          document.getElementById('curtain').classList.add('show')

      });
      
      /* 
      ** Add close button
      */
      // define container to which the close button should be added to
      var $container = Foundation.MediaQuery.only('small') ? $('#dynamic-reveal') : $('#dynamic-reveal').parent();
       
      $('<div/>', {
          "class": 'close-button',
          html: '<span aria-hidden="true">&times;</span>',//closeButton,
          'aria-label': 'Close alert',
          'type': 'button',
          'data-close' : ''
      }).appendTo($container);

      // Activate "on close" event
      $('#dynamic-reveal[data-reveal]').on('closed.zf.reveal', function () {
          $(this).foundation('_destroy').remove();
          document.getElementById('curtain').classList.remove('show')
      });
  }
}


$('.show-more').on('click', function(){   
    var textWrap = $(this).parent().find('.text-wrap');
    //$(this).parents('.text-wrap').find('.hide').slideToggle();
    textWrap.slideToggle();
    $(this).find('span').toggleClass('is-active');

})