// custom dependencies
// import AOS from 'aos';
import objectFitImages from 'object-fit-images';
import Cookie from 'js-cookie';
//import { Turbo } from "@hotwired/turbo"
// import * as Turbo from "@hotwired/turbo"
// import _ from 'underscore';
// import Headroom from "headroom.js";
import photoswipe from 'photoswipe';

/* RET DET HER - SKAL IKKE LOADES FRA BUNDLE - ALLE MODULER ER MED */
// import Swiper from 'swiper';

// import Swiper core and required modules
//import SwiperCore, { Navigation, Pagination, Lazy } from 'swiper';
// import SwiperCore, { Navigation, Pagination, Lazy } from 'swiper/core';

  // import Swiper styles
  // import 'swiper/swiper-bundle.css';
  // import Swiper bundle with all modules installed
  //import Swiper from 'swiper/bundle';



import lozad from 'lozad';

const deps = {
//   AOS: AOS,
  objectFitImages: objectFitImages,
  Cookie: Cookie,
  PhotoSwipe: photoswipe,
//   Turbo: Turbo,
  // _:_
//   Headroom:Headroom,
//   Swiper:Swiper,
  lozad:lozad
};

export default deps;
